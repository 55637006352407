<template>

    <section id="bantuan" >
        <div class="container py-5">
            <div class="row justify-content-center text-center">
                <div class="col-md-6">
                    <h3 class="email-t1">Situs Bantuan </h3>
                    <br/><br/>
                    <h4 class="email-t1">Penggunaan Tamaska Loket</h4>
                    <br/>
                    <div class="row">
                            <div class="col-md-6">

                                <a target="bantuan" href="/data_bantuan/loketapps.pdf" class="email-t2">
                                    <img src="../assets/img/loketapps.png" alt="" class="modul-img img-fluid">
                                    <div class="mt-6">
                                        <p>&nbsp;</p>
                                        <h5 class="email-t2">Panduan Penggunaan <br/>Mobile Apps Petugas Loket</h5>
                                    </div>
                                </a>
                            </div>

                        <div class="col-md-6">
                            <a target="bantuan" href="/data_bantuan/loketSIM.pdf" class="email-t2">
                                <img src="../assets/img/loketSIM.png" alt="" class="modul-img img-fluid">
                                <div class="mt-6">
                                    <p>&nbsp;</p>
                                    <h5 class="email-t2">Panduan Penggunaan <br/>SIM Pengelolaan Loket</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container py-5">
            <div class="row justify-content-center text-center">
                <div class="col-md-6">
                    <h4 class="email-t1">Penggunaan Tamaska Loket Desa</h4>
                    <br/>
                    <div class="row">
                        <div class="col-md-6">
                            <a target="bantuan" href="/data_bantuan/desaapps.pdf" class="email-t2">
                                <img src="../assets/img/desaapps.png" alt="" class="modul-img img-fluid">
                                <div class="mt-6">
                                    <p>&nbsp;</p>
                                    <h5 class="email-t2">Panduan Penggunaan <br/>Mobile Apps Petugas Loket Desa</h5>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-6">
                            <a target="bantuan" href="/data_bantuan/desaSIM.pdf" class="email-t2">
                                <img src="../assets/img/desaSIM.png" alt="" class="modul-img img-fluid">
                                <div class="mt-6">
                                    <p>&nbsp;</p>
                                    <h5 class="email-t2">Panduan Penggunaan <br/>SIM Pengelolaan Loket Loket Desa</h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  <br/><br/>
</template>

<style scoped>


	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}
	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}

    input,textarea{
        outline: none;
    }

    .email-t1 {
        color: #8D6CBA;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }
    .email-t2 {
        color: #485273;
        /* font-size: 36px; */
        font-family: 'Barlow-Medium';
        font-weight: 500;
    }

    .label-name {
        float: left;
        text-align: left;
        padding-left: 33px;
        padding-bottom: 5px;
        font-family: 'Barlow-Medium';
    }

    .input-form {
        float: left;
        text-align: left;
    }

    .input-area {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    textarea.form-input:focus,
    input.form-input:focus
    {
        border: 2px solid #5EA84A;
    }
    textarea.form-input,

    input.form-input{
        border: 2px solid #9E9E9E;
        border-radius: 20px;
        opacity: 1;
        /* min-width: 410px; */
        min-width: 540px;
        padding: 10px 10px 10px 20px;
    }
    .row.input-area-group {
        margin-top: 20px;
    }
    label.form-input {
        left: 20px;
        position: relative;
    }
    .info-email {
        color: #485371;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }
    .sub-info{
        color: #5EA84A;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }

    a.email-t2:hover {
        text-decoration: underline;
    }
    a.email-t2 {
        text-decoration: auto;
    }
</style>