<template>
        <transition>
            <p class="float-end" v-if="showBack">
                <a class="a-float-end backtotop" href="#">
                    <img alt="Back to Top" class="backtotop" src="../assets/img/backtotop.png"/>
                </a>
            </p>
        </transition>

        <footer id="kontak" class="bg-dark-blue container bg-footer">
            <div class="container py-5">
                <div class="row justify-content-center text-center text-md-start">
                    <div class="col-lg-2 col-2 col-md-2">
                        <img src="../assets/img_cp/scn-logo-1.png" alt="" class="logo-footer img-fluid mb-4 mb-md-0"></div>
                    <div class="col-lg-4 col-md-4 contact-footer">
                        <h5 class="fw-bold">Smart City Nusantara <br>
                            PT. Telekomunikasi Indonesia, Tbk</h5>
                        <p>Jl. Gunung Sahari Raya, No.53, Jakarta Pusat <br>
                            Living Lab Smart City Nusantara <br>
                            No. Telepon : +6281119091091 <br>
                            Email : contact@tamaska.id
                            </p>
                    </div>
                    <div class="col-lg-6 col-md-6 text-center text-md-start text-lg-end align-self-center my-3 my-md-6">
                        <div>
                            <a href="/kirim_email" type="button" class="btn rounded-pill btn-dark-blue2 px-4 mx-1">
                                <i class="fa-solid fa-envelope-open-text" aria-hidden="true" ></i> Email kami
                            </a>
                            <a href="/bantuan" type="button" class="btn rounded-pill btn-dark-blue2 px-4 mx-1">
                                <i class="fa-solid fa-circle-question" aria-hidden="true" ></i>
                                Situs bantuan
                            </a>
                                <img height="70" src="../assets/img_cp/telkom-white1.png" alt="" class="mt-5 mt-md-0"></div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="mt-5 small ps-lg-12 ms-lg-12 cp-footer">
                            <p><a href="/privacy" class="text-white">Kebijakan Privasi</a><span class="mx-2"> | </span> Copyright ©
                                2022. Hak Cipta Dilindungi</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


</template>

<style>
    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }
    .h3, h3 {
        font-size: calc(1.3rem + .6vw);
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        /* font-weight: 500; */
        line-height: 1.2;
    }
    .h3, h3 {
        font-size: calc(1.3rem + .6vw);
    }
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        /* font-weight: 500; */
        line-height: 1.2;
    }
    *, :after, :before {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }

    .v-enter-active,
    .v-leave-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

</style>
<script>
	const setOffsetTop = 100;

    export default ({
        data() {
            return{
                showBack: false
            }

        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll(e) {
                if(e.target.documentElement.scrollTop > (setOffsetTop)){
                    this.showBack = true
                }else{
                    this.showBack = false
                }

            }
        }
    })
</script>
