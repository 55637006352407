<template>
	<Header/>
	<router-view/>
	<Footer  v-if="isHome || isEmail || isBantuan" />
</template>
<script>
    import "bootstrap/dist/css/bootstrap.min.css"
    import "bootstrap"

    import Header from "@/components/Header.vue"
    import Footer from "@/components/Footer.vue"

	export default {
		name: 'App',
		components : { Header,Footer },
		computed: {
			isHome() {
				return this.$route.path === '/'
			},
			isEmail() {
				return this.$route.path === '/kirim_email' || this.$route.path === '/kirim_email/'
			},
			isBantuan() {
				return this.$route.path === '/bantuan' || this.$route.path === '/bantuan/'
			}
		}

    }

</script>


<style>
	@import '@/assets/css/carousel.css';

	@import '@/assets/css/fontawesome.min.css';
	@import '@/assets/css/brands.min.css';
	@import '@/assets/css/solid.min.css';

    :root {
	--main-color: #5EA84A;
	--secondary-color: #8D6CBA;
	}

	/* @font-face {
		font-family: "Barlow";
		src: url("@/assets/fonts/Barlow-Regular.ttf");
		font-weight: normal;
	}
	@font-face {
		font-family: "Barlow";
		src: url("@/assets/fonts/Barlow-Bold.ttf");
		font-weight: 700;
	} */

	@font-face {
		font-family: "Barlow";
		src: url("@/assets/fonts/Barlow-Regular.ttf");
	}

	@font-face {
		font-family: "Barlow-Regular";
		src: url("@/assets/fonts/Barlow-Regular.ttf");
	}

	@font-face {
		font-family: "Barlow-Medium";
		src: url("@/assets/fonts/Barlow-Medium.ttf");
	}

	@font-face {
		font-family: "Barlow-Bold";
		src: url("@/assets/fonts/Barlow-Bold.ttf");
	}

	@font-face {
		font-family: "Barlow-ExtraBold";
		src: url("@/assets/fonts/Barlow-ExtraBold.ttf");
	}

	#app {
      	/* font-family: Avenir, Helvetica, Arial, sans-serif; */
      	font-family: Barlow,sans-serif;
		/* font-family: "Barlow"; */
		/* src: url("@/assets/fonts/Barlow-Regular.ttf"); */
      	/* -webkit-font-smoothing: antialiased; */
      	/* -moz-osx-font-smoothing: grayscale; */
      	/* text-align: center; */
      	color: #485372;
      	margin-top: 60px;
    }
	.bd-placeholder-img {
		font-size: 1.125rem;
		text-anchor: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	@media (min-width: 768px) {
		.bd-placeholder-img-lg {
			font-size: 3.5rem;
		}
	}

	.b-example-divider {
		height: 3rem;
		background-color: rgba(0, 0, 0, .1);
		border: solid rgba(0, 0, 0, .15);
		border-width: 1px 0;
		box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
	}

	.b-example-vr {
		flex-shrink: 0;
		width: 1.5rem;
		height: 100vh;
	}

	.bi {
		vertical-align: -.125em;
		fill: currentColor;
	}

	.nav-scroller {
		position: relative;
		z-index: 2;
		height: 2.75rem;
		overflow-y: hidden;
	}

	.nav-scroller .nav {
		display: flex;
		flex-wrap: nowrap;
		padding-bottom: 1rem;
		margin-top: -1px;
		overflow-x: auto;
		text-align: center;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
	}

	div#navbarCollapse {
		flex-grow: 0;
	}

	a.nav-link {
		font-weight: 600;
	}


	.body-content-1 {
		display: block;
		/* background-image: url(@/assets/img/bannerhome.png); */
		background-image: url(@/assets/img/homebanner_elok.png);
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		/*height: 500px;*/
		/*height: 325px;*/

		height: 32rem;
		/*height: 325px;*/
		position: relative;
		/*display: none;*/
		float: left;
		width: 100%;
		margin-right: -100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transition: transform .6s ease-in-out;
	}

	.body-content-1-caption {
		/* bottom: 3rem; */
		z-index: 10;
		position: relative;
		right: 15%;
		bottom: 1.25rem;
		left: 5%;
		padding-top: 5.25rem;
		/* padding-bottom: 1.25rem; */
		color: #fff;
		text-align: left;
	}

	.carousel-caption.text-start.text-color {
		color: #485372;
	}
	.bg-footer{
		background-color: darkgray;
		margin-right: 0px;
		margin-left: 0px;
		max-width: 100%;
	}
	a.a-float-end {
		color: white;
	}

	.text-main-color,
	.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
		color:var(--main-color);
	}
	a.btn.btn-main.rounded-pill.btn-secondary {
		background-color: var(--main-color);
	}
	.btn-main-color{
		background-color: var(--main-color);
	}
	.btn-secondary-color {
		background-color: var(--secondary-secondary);
	}

	h2{
		font-weight: 700;
	}
	.text-secondary-color {
		color: var(--secondary-color);
	}

	img.modul-img.img-fluid {
		max-height: 200px;
	}

	section {
		scroll-margin-top: 3em;
	}

	img.backtotop {
		margin-top: -100px;
		margin-right:10px;
		width: 45px;
	}
	footer#kontak {
		background-color: #485371;
		color: white;
	}

	a.btn.rounded-pill.btn-dark-blue2.px-4.mx-1 {
		background-color: #31384D;
		color: white;
	}
	small.backtotop {
		margin-top: -49px;
		position: absolute;
		right: 80px;
		color: #485372;
		line-height: normal;
		text-align: end;
		width: 80px;
	}

    .bg-light-grey {
        background: #E9E9E9 0% 0% no-repeat padding-box;
    }
    button.carousel-control-prev,button.carousel-control-next {
        color: darkgray;
        opacity: 0.5;
    }

    .carousel-control-prev:hover
    ,.carousel-control-next:hover
    {
        color: gray;
        text-decoration: none;
        outline: 0;
        opacity: .9;
    }

    body{
        padding-top: 0;
        padding-bottom: 0;
    }


	.container-fluid {
		margin: 0 60px;
	}

	a.btn.btn-secondary.rounded-pill.btn-main-color.px-6.btn-site {
		width: 150px;
	}

	.col-lg-7.col-md-7.col-sm-7.col-xs-12.box-fitur {
		padding-right: 30px;
	}

	img.img-responsive.info-loket {
		right: -20px;
		position: relative;
	}
	img.img-fluid.rounded-start.label-desa {
		position: absolute;
		top: 13px;
		left: -2px;
		width: 13%;
	}
	.float-end {
		float: right!important;
		right: 8px;
		position: fixed;
		bottom: -40px;
	}
	img.img-pin.reguler {
		width: 14px;
	}
	a#btn-solusi:active {
		color:var(--main-color)
	}

	nav.navbar.navbar-expand-md.navbar-light.fixed-top.bg-light {
		padding: 0;
	}

	a.nav-link.active{
		color: white;
		background-color: var(--main-color);
		font-weight: 600;

	}
	.nav-link.active{
		color: white;
		background-color: var(--main-color);
	}
	.nav-link{
		padding-top: 15px;
		height: 60px;
	}

	span.text-main-color {
		color: var(--main-color);
	}

	.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
		color: white;
	}

	.carousel-caption.text-start.text-color {
		top: 20px;
	}
	i.fa-solid.fa-lg.fa-angle-left {
		font-size: 26px;
	}

	i.fa-solid.fa-lg.fa-angle-right {
    	font-size: 26px;
	}
	.text-bold{
		font-family: "Barlow-Bold";
	}
	.fw-bold{
		font-family: "Barlow-Bold";
	}
	.nav-link{
		font-family: "Barlow-Bold";
		color: #485273;
	}

	.tamaska-poin{
		/* background-image: url(@/assets/img/pointkeunggulan_2.png); */
		width: 16px;
	}

	a.btn.btn-primary.rounded-pill.btn-main-color.px-4.fw-bold {
		border: 0px;
	}

	a.btn.btn-primary.rounded-pill.btn-main-color.px-4.fw-bold:hover {
		background-color: var(--secondary-color);
	}
	img.logo-footer.img-fluid.mb-4.mb-md-0 {
		max-width: 142px;
	}
	.col-lg-5.col-md-6.contact-footer {
		padding-left: 64px;
	}
	.mt-5.small.ps-lg-5.ms-lg-5.cp-footer {
		padding-left: 114px !important;
	}
</style>