import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'
import Email from '../views/Email.vue'
import Bantuan from '../views/Bantuan.vue'

const routes = [
	{ name: Home, path: '/', component: Home, meta: { scrollToTop: true } },
	{ name: Privacy, path: '/privacy', component: Privacy, meta: { scrollToTop: true } },
	{ name: Email, path: '/kirim_email', component: Email, meta: { scrollToTop: true } },
	{ name: Bantuan, path: '/bantuan', component: Bantuan, meta: { scrollToTop: true } }
]

const router = createRouter({

	history: createWebHistory(process.env.BASE_URL),
	routes,
	methods: {
		scrollFunction() {
			console.log("scrolling from method");
		}
	},
	mounted() {
		window.addEventListener("scroll", this.scrollFunction);
	},

	scrollBehavior(to, _from, _savedPosition) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
				top: 30
			};
		}
	}
})

export default router
