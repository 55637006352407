<template>
    <header>
        <nav class="navbar navbar-expand-md navbar-light fixed-top bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="/#">
                    <img alt="logo" src="../assets/img/logo-tmas-loket-hd.png" height="45">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav me-auto mb-2 mb-md-0"  v-if="isHome || isEmail || isBantuan" >
                        <li class="nav-item">
                            <a id="btn-home" href="/#" class="nav-link active" >Beranda</a>
                        </li>
                        <li class="nav-item">
                            <router-link id="btn-solusi" class="nav-link" to="/#solusi"   >Solusi</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link id="btn-keunggulan" class="nav-link" to="/#keunggulan"  >Keunggulan</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link id="btn-modul" class="nav-link" to="/#modul" >Modul</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link id="btn-pelanggan" class="nav-link" to="/#pelanggan" >Pelanggan</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link id="btn-kontak" class="nav-link" to="/#kontak" >Kontak</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
	export default {
		computed: {
			isHome() {
				return this.$route.path === '/'
			},
			isEmail() {
				return this.$route.path === '/kirim_email' || this.$route.path === '/kirim_email/'
			},
			isBantuan() {
				return this.$route.path === '/bantuan' || this.$route.path === '/bantuan/'
			}
        }

    }

</script>