<template>
  <section id="email">
    <div class="container">
      <div class="row">
            <center>
                <form @submit.prevent="sendEmail">
                    <div class="col-lg-10 col-md-10">
                        <h4 class="email-t1">
                            Kami siap membantu dan membuat Anda lebih mengenal Tamaska Loket
                        </h4>
                        <h5 class="email-t2">
                            Kirimkan pertanyaan, laporan, feedback kepada kami
                            <br/>
                            dengan mengisi formulir dibawah :
                        </h5>

                        <div v-if="!emailSuccess || true" class="input-area">
                            <div class="col-lg-6 col-md-6">
                                <div  class="row input-area-group">
                                    <div  class="row">
                                        <label class="label-name">
                                            NAMA
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="input-form">
                                            <input v-show="vshow_input" class="form-input" placeholder="Nama Lengkap" type="text" v-model="inp_name" autofocus required/>
                                            <label v-show="vshow_label" class="form-input" for="inp_name">{{inp_name}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div  class="row input-area-group">
                                    <div  class="row">
                                        <label class="label-name">
                                            E-MAIL
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="input-form">
                                            <input v-show="vshow_input" class="form-input" placeholder="ex: alamatemail@mail.com" type="email" v-model="inp_email" required/>
                                            <label v-show="vshow_label" class="form-input" for="inp_email">{{inp_email}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div  class="row input-area-group">
                                    <div  class="row">
                                        <label class="label-name">
                                            SUBJEK
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="input-form">
                                            <input v-show="vshow_input" class="form-input" placeholder="Judul pesan" type="text" v-model="inp_subject" required/>
                                            <label v-show="vshow_label" class="form-input" for="inp_subject">{{inp_subject}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div  class="row input-area-group">
                                    <div  class="row">
                                        <label class="label-name">
                                            PESAN
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="input-form">
                                            <textarea v-show="vshow_input" rows="10" class="form-input" placeholder="Tuliskan pesan yang ingin Anda sampaikan disini" v-model="inp_msg" required></textarea>
                                            <label v-show="vshow_label" class="form-input" for="inp_msg"><p style="white-space: pre-line;">{{ inp_msg }}</p></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                            <div v-if="!emailSuccess" class="col-lg-6 col-md-6">
                                <div class="row">
                                    <div class="input-form">
                                        <div id="captcha">
                                        </div>
                                        <input type="text" placeholder="Captcha" id="cpatchaTextBox"/>
                                            <br/><br/>
                                            <br/>
                                            <button type="submit" class="btn btn-primary rounded-pill btn-main-color px-4 fw-bold">
                                                Kirim pesan
                                            </button>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            <div v-if="emailSuccess" class="row" >
                                <span style="color:#5EA84A">
                                    E-Mail telah dikirim.
                                </span>
                                <br/>
                                <br/>
                            </div>
                            <div v-if="emailError400" class="row" >
                                <span style="color:red">
                                    Gagal!, terjadi kesalahan pada input.
                                </span>
                                <br/>
                                <span>
                                    Silahkan coba kembali
                                </span>
                                <br/>
                                <small>atau</small>
                            </div>
                            <div v-if="emailError415" class="row" >
                                <span style="color:red">
                                    Gagal!, Server tidak dapat mengirim pesan.
                                </span>
                                <br/>
                                <span>
                                    Silahkan coba kembali
                                </span>
                                <br/>
                                <small>atau</small>
                            </div>
                            <div v-if="emailSuccess || emailError400 || emailError415" class="row" >
                                <br /><a href="/">&laquo; kembali ke Beranda</a>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                </form>
            </center>
      </div>
    </div>
  </section>
  <section class="bg-light-grey" id="info-email">
    <div class="container">
        <div class="row">
            <center>
                <br/>
                <span class="info-email">
                    Untuk masalah Teknis dapat menghubungi kami pada <span><u><a class="sub-info" href="/#kontak">halaman kontak</a></u></span> pada Portal Tamaska Loket
                </span>
                <br/>
                <br/>
            </center>
        </div>
    </div>
  </section>
  <br/><br/>
</template>

<script>
    export default {
        el:'#email',
		name: 'Email',
		data() {
			return {

				inp_email : '',
				inp_name : '',
				inp_subject : '',
				inp_msg : '',

				vshow_input : true,
				vshow_label : false,
                emailSuccess : false,
                emailError400 : false,
                emailError415 : false,
                code : false
    		}
        },
        mounted(){
            this.createCaptcha()
        },
        methods: {
            createCaptcha : function(){
    //clear the contents of captcha div first
                document.getElementById('captcha').innerHTML = "";
                var charsArray =
                "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var lengthOtp = 6;
                var captcha = [];
                for (var i = 0; i < lengthOtp; i++) {
                    //below code will not allow Repetition of Characters
                    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
                    if (captcha.indexOf(charsArray[index]) == -1)
                    captcha.push(charsArray[index]);
                    else i--;
                }
                var canv = document.createElement("canvas");
                canv.id = "captcha";
                canv.width = 100;
                canv.height = 50;
                var ctx = canv.getContext("2d");
                ctx.font = "25px Georgia";
                ctx.strokeText(captcha.join(""), 0, 30);
                //storing captcha so that can validate you can save it somewhere else according to your specific requirements
                this.code = captcha.join("");
                document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
            }
            ,sendEmail : function(){
                const baseURL   = process.env.VUE_APP_API_PATH_POST_MAIL;
                const token     = process.env.VUE_APP_API_TOKEN_POST_MAIL;

                if (document.getElementById("cpatchaTextBox").value == this.code) {
                    alert("Pesan dikirim...");

                    const data_post = JSON.stringify(
                                    {
                                        nama_lengkap: this.inp_name,
                                        email: this.inp_email,
                                        subjek: this.inp_subject,
                                        pesan: this.inp_msg,
                                        sent_from: "Tamaska Loket"
                                    }
                                )




                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer '+ token,
                            'Content-Type': 'application/json'
                            },
                        body: data_post

                    };

                    fetch(baseURL, requestOptions)
                        .then(async response => {
                            const data = await response.json();
                            console.log(response);

                            if (!response.ok) {
                                const error = (data && data.message) || response.status;
                                return Promise.reject(error);
                            }

                            if(data.meta.code == 200){
                                this.emailSuccess = true;
                                this.emailError400 = false;
                                this.emailError415 = false;
                                this.vshow_input = false;
                                this.vshow_label = true;
                            }else if(data.meta.code == 400){
                                this.emailError400 = true;
                            }else if(data.meta.code == 415){
                                this.emailError415 = true;
                            }
                            console.log(data);
                        })
                        .catch(error => {
                            this.errorMessage = error;
                            console.error('There was an error!', error);
                        });

                }else{
                    alert("Invalid Captcha. try Again");
                    this.createCaptcha();
                }

            }
        }
    }


</script>

<style scoped>


	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}
	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}

    input,textarea{
        outline: none;
    }

    .email-t1 {
        color: #8D6CBA;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }
    .email-t2 {
        color: #485273;
        /* font-size: 36px; */
        font-family: 'Barlow-Medium';
        font-weight: 500;
    }

    .label-name {
        float: left;
        text-align: left;
        padding-left: 33px;
        padding-bottom: 5px;
        font-family: 'Barlow-Medium';
    }

    .input-form {
        float: left;
        text-align: left;
    }

    .input-area {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    textarea.form-input:focus,
    input.form-input:focus
    {
        border: 2px solid #5EA84A;
    }
    textarea.form-input,

    input.form-input{
        border: 2px solid #9E9E9E;
        border-radius: 20px;
        opacity: 1;
        /* min-width: 410px; */
        min-width: 540px;
        padding: 10px 10px 10px 20px;
    }
    .row.input-area-group {
        margin-top: 20px;
    }
    label.form-input {
        left: 20px;
        position: relative;
    }
    .info-email {
        color: #485371;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }
    .sub-info{
        color: #5EA84A;
        /* font-size: 44px; */
        font-family: 'Barlow-Bold';
    }

</style>