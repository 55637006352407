<template>
  <section id="privacy">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-md-offset-2">
          <div class="kebijakan" style="margin-bottom: 30px">
            <h3>Privasi dan Kebijakan</h3>
            <p>
              Kebijakan privasi ini bertujuan untuk membantu Anda mengerti informasi apa yang kami kumpulkan,tujuan kami mengumpulkan informasi dan bagaimana Anda dapat mengganti ataupun menghapus informasi Anda.
            </p>
            <h4>Informasi Yang Kami Kumpulkan</h4>
            <p>
              Ketika membuat akun di layanan Tamaska Loket, Anda memberikan informasi alamat email dan password Anda. Kami mengumpulkan informasi privasi Anda yang meliputi Nama, Alamat, dan Nomor Ponsel untuk melengkapi dan memvalidasi akun Anda. Kami juga mengumpulkan informasi lokasi dan waktu Anda menggunakan layanan kami.
            </p>
            <h4>Mengakses dan Memperbarui Informasi Pribadi</h4>
            <p>
              Jika Anda memiliki akun di layanan Tamaska Loket,  Anda dapat mengakses rincian transaksi Anda dengan cara masuk ke akun Anda di perangkat smartphone. Anda dapat melihat rincian dan mengelola informasi pribadi Anda. Anda dapat memperbarui Informasi pribadi Anda kapan saja dengan mengakses akun Anda dalam aplikasi mobile Tamaska Loket  melalui perangkat smartphone atau mengakses situs web layanan Tamaska Loket.
            </p>
            <h4>Keamanan Informasi Pribadi Anda</h4>
            <p>
              Layanan kami memastikan bahwa seluruh informasi yang dikumpulkan tersimpan dengan aman. Kami menjaga informasi pribadi Anda dengan cara:
            </p>
            <ul>
              <li>Membatasi akses ke informasi pribadi</li>
              <li>
                Mengikuti kemajuan teknologi pengamanan untuk mencegah akses tidak sah
              </li>
            </ul>
            <h4>Tujuan Pengumpulan Data.</h4>
            <p>
              Kami menggunakan data pribadi Anda untuk melakukan validasi akun Anda karena layanan kami memiliki fitur pembayaran secara online bekerjasama dengan pihak lain penyedia fitur pembayaran online. Layanan kami juga mengumpulkan informasi lokasi Anda pada saat menggunakan layanan untuk meningkatkan kenyamanan Anda dan keakuratan layanan. Layanan kami mungkin menggunakan cookies, dan teknologi serupa untuk menyimpan informasi dalam rangka memberi Anda pengalaman yang lebih baik, lebih cepat, lebih aman dan personal ketika Anda menggunakan Layanan kami.
            </p>
            <p>
              Ketika Anda menggunakan layanan kami, server kami akan secara otomatis menyimpan informasi Anda termasuk:
            </p>
            <ul>
              <li>Alamat IP komputer Anda</li>
              <li>Tipe browser</li>
              <li>Halaman-halaman dalam Platform yang Anda kunjungi</li>
              <li>
                Waktu yang dihabiskan pada halaman tersebut, dan informasi yang dicari, waktu akses dan tanggal, dan statistik lainnya.
              </li>
            </ul>
            <p>
              Informasi ini dikumpulkan untuk analisa dan evaluasi guna membantu kami meningkatkan layanan dan produk yang kami sediakan.
            </p>
            <h4>Perubahan Kebijakan Privasi</h4>
            <p>
              Kami dapat secara berkala meninjau Kebijakan Privasi ini. Kami berhak untuk memodifikasi dan mengubah kebijakan privasi setiap saat. Setiap perubahan kebijakan ini akan dipublikasikan.
            </p>
            <br /><a href="/">kembali ke depan</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
	nav.navbar.navbar-expand-md.navbar-light.fixed-top.bg-light {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	header{
	    padding-bottom: 0.5rem;
	}

	body{
		color: black;
	}
	h3,h4{
		font-family: Barlow-Bold;
	}

	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}
	@media (min-width: 992px){
		section {
			padding-top: 12px;
		}
	}
	#privacy {
		color: black;
		-webkit-font-smoothing: revert;
	}

</style>