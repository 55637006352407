<template>
<main>
	<div class="row">
		<div class="container">

			<div class="body-content-1">

				<div class="container">
					<div class="row">
						<div class="col-lg-7 text-white body-content-1-caption">
							<div class="cover-text mb-4">
								<h1 class="fw-bold portal-header">
									{{ info }}
								</h1>
								<p>
									Tamaska Loket adalah inovasi pengelolaan tiket/loket secara elektronik untuk Pengelola Kawasan yang hadir sebagai solusi untuk optimalisasi pengelolaan dan pemanfaatan aset.
									<br/><br/>
									Salah satu implementasi Tamaska Loket adalah di lingkup desa khususnya untuk mendukung pengembangan aspek smart economy desa. Dengan karakter implementasi nya yang spesifik berkaitan dengan ekosistem dan stakeholder desa, maka dikembangkan Tamaska Loket Desa sebagai bentuk layanan Tamaska Loket di lingkup Desa.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div class="row">
	</div>



		<div id="articless">
				<section id="beranda">

		<div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
			<div class="carousel-indicators">
				<button class="btn-slide-carousel active" type="button" data-bs-target="#myCarousel" data-bs-slide-to="0"
					aria-current="true" aria-label="Slide 1"></button>
				<button class="btn-slide-carousel" type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
			</div>

			<div class="carousel-inner">

				<div class="carousel-item active">

					<div class="container">
						<div class="carousel-caption text-start text-color">

							<div class="row">
								<div class="row">
									<h2 class="easy-step-title mb-5">
										<div class="text-secondary-color text-center text-bold">
											Mudah digunakan <span class="text-main-color">Petugas Loket</span>
										</div>
									</h2>
								</div>
								<div class="col-lg-4">
									<div class="content text-center">
										<img src="../assets/img/illustrasi_elok1.1.png" alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4">Cukup install aplikasi Petugas Loket</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="content text-center">
										<img src="../assets/img/illustrasi_elok1.2.png" alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4">Praktis dalam operasional dengan didukung pembayaran tunai dan non tunai</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="content text-center">
										<img src="../assets/img/illustrasi_elok1.3.png" alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4">Data tercatat secara akurat</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="carousel-item">
					<div class="container">
						<div class="carousel-caption text-start text-color">

							<div class="row">
								<div class="row">
									<h2 class="easy-step-title mb-5">
										<div class="text-secondary-color text-center text-bold">
											Memudahkan <span class="text-main-color">Pengelola Loket</span>
										</div>
									</h2>
								</div>
								<div class="col-lg-4">
									<div class="content text-center"><img src="../assets/img/illustrasi_elok2.1.png"
											alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4"> Lokasi aset loket & Petugas Loket terdata </div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="content text-center"><img src="../assets/img/illustrasi_elok2.2.png"
											alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4">Monitoring Transaksi Real Time</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="content text-center"><img src="../assets/img/illustrasi_elok2.3.png"
											alt="Preview" class="img-fluid">
										<div>
											<div class="mt-4">Statistik & analitik setiap lokasi aset loket </div>

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>



			</div>
			<button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
				<span class="carousel-control-prev-icon-" aria-hidden="true">
					<i class="fa-solid fa-lg fa-angle-left" aria-hidden="true" ></i>
				</span>
				<span class="visually-hidden">Previous</span>
			</button>
			<button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">

				<span class="carousel-control-next-icon-" aria-hidden="true">
					<i class="fa-solid fa-lg fa-angle-right" aria-hidden="true" ></i>
				</span>
				<span class="visually-hidden">Next</span>
			</button>
		</div>
				</section>
				<section id="solusi" class="bg-light-grey">
					<div class="container">
						<div>
							<div class="row justify-content-center py-5 text-center">
								<div class="col-md-10">
									<h2 class="text-secondary-color fw-bold text-bold">Solusi Pengelolaan Loket Elektronik</h2>
									<p>
										Layanan Tamaska Loket hadir sebagai inovasi dalam menyelesaikan permasalahan dan tantangan pengelolaan loket bagi Pengelola Kawasan. Beragam modul aplikasi dihadirkan dalam pelayanan

										Tamaska Loket, antara lain:
									</p>
									<div class="row mt-5">
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi1.png" alt="">
											<p>Dashboard monitoring setiap transaksi pembayaran tiket secara real time.</p>
										</div>
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi2.png" alt="">
											<p>Modul pendataan lokasi loket dan Petugas Loket.</p>
										</div>
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi3.png" alt="">
											<p>Modul pengelolaan tarif beragam jenis tiket dan loket.</p>
										</div>
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi4.png" alt="">
											<p>Modul manajemen hak akses untuk pengaturan hak akses dan role akses sesuai dengan tupoksi tugas dan wewenang.</p>
										</div>
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi5.png" alt="">
											<p>Modul penerbitan & pengelolaan karcis berkode (untuk transaksi pembayaran tunai)</p>
										</div>
										<div class="col-md-4 my-2">
											<img class="img-fluid mb-3" src="../assets/img/solusi6.png" alt="">
											<p>Modul pembayaran loket secara elektronik (non-tunai) menggunakan smartphone.</p>
										</div>
										<div class="col-12">
											<div>
												<a href="/kirim_email" type="button" class="btn btn-primary rounded-pill btn-main-color px-4 fw-bold">Request demo</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>


				<section id="keunggulan" class="py-5 white area">
					<div class="container">
						<div class="row wrapper clearfix">
							<!---->
							<div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 box-fitur">
								<div class="content text-left">
									<div class="mb-4">
										<h2 class="text-secondary-color fw-bold text-bold">Keunggulan Tamaska Loket</h2>
										<p>Beragam modul aplikasi dihadirkan dalam pelayanan Tamaska Loket, ditujukan untuk menghadirkan keunggulan pengelolaan tiket/loket, antara lain:</p>
									</div>
									<div class="row align-items-center">
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pelayanan loket dan penyediaan tiket yang lebih mudah dan cepat bagi pengguna.
										</div>
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung fleksibilitas pembayaran tunai dan non tunai.
										</div>
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pengoptimalisasian aset dan pendapatan Pengelola Kawasan dari pelayanan loket dan penyediaan tiket.
										</div>
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pencatatan data transaksi tiket secara akurat dan lengkap.
										</div>
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>

										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pelaksanaan pengawasan kinerja Petugas Loket dan transaksi di semua lokasi loket.
										</div>
										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pelaporan transaksi secara <em>real time</em>.
										</div>

										<div class="col-1 my-1 text-center">
											<img class="tamaska-poin" aria-hidden="true" src="@/assets/img/pointkeunggulan_2.png">
										</div>
										<div class="col-11 my-1 px-0 list-fitur">
											Mendukung pengelolaan tarif dan lokasi loket dengan mudah.
										</div>
									</div>
								</div>
							</div>
							<div class="gambar col-lg-5 col-md-5 col-sm-5 col-xs-12">
								<div class="content"><img alt="ilustrasi" src="../assets/img/info-loket-hd.png" width="100%" class="img-responsive info-loket"></div>
							</div>
						</div>
					</div>
				</section>

				<section id="modul" class="bg-light-grey">
					<div class="container py-5">
						<div class="row justify-content-center text-center">
							<div class="col-md-10">
								<h2 class="text-secondary-color fw-bold text-bold">Modul</h2>
								<div class="row">
									<div class="col-md-6"><img src="../assets/img/display-elok-dashboard.png" alt="" class="modul-img img-fluid">
										<div class="mt-6">
											<p>&nbsp;</p>
											<h5 class=" text-bold"><strong>SIM Pengelolaan Loket</strong></h5>
											<p>Merupakan sistem informasi manajemen yang digunakan oleh Pengelola Kawasan untuk pengaturan sistem dan monitoring pengelolaan loket. SIM mengakomodir fleksibilitas operasional dan berbasis role akses sehingga dapat disesuaikan dengan kebutuhan Pengelola Kawasan.</p>
										</div>
									</div>

									<div class="col-md-6"><img src="../assets/img/display-elok-apps.png" alt="" class="modul-img img-fluid">
										<div class="mt-6">
											<p>&nbsp;</p>
											<h5 class=" text-bold"><strong>Mobile Apps Petugas Loket</strong></h5>
											<p>
												Merupakan aplikasi berbasis android yang dapat digunakan oleh Petugas Loket dalam melakukan operasionalisasi loket/transaksi pada loket. Aplikasi ini terkoneksi ke back-end system secara online sehingga seluruh transaksi tercatat secara real time dan didukung oleh metode pembayaran tunai dan non tunai (QRIS).
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			<section id="pelanggan">
			<div class="container py-5">
				<div class="row justify-content-center text-center">


					<div class="col-md-10">
						<h2 class="text-secondary-color fw-bold text-bold">Portofolio Tamaska Loket</h2>
						<p>Daftar Pelanggan yang Turut Berpartisipasi Memaksimalkan Pengelolaan Kawasannya dengan Tamaska Loket :</p>

						<div class="row" v-if="slidePelanggan">
							<div id="porto-carousel" class="carousel slide" data-bs-ride="carousel">
								<div class="carousel-indicators">
									<button v-if="jdata_Loket.length >= 1" class="btn-slide-carousel active" type="button" data-bs-target="#porto-carousel" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
									<button v-if="jdata_Loket.length >= 3" class="btn-slide-carousel" type="button" data-bs-target="#porto-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
									<button v-if="jdata_Loket.length >= 5" class="btn-slide-carousel" type="button" data-bs-target="#porto-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
								</div>

								<div   class="carousel-inner porto">

									<div v-if="jdata_Loket.length >= 1" class="carousel-item porto active">
										<div class="container">
											<div class="row">
												<div v-for="rdata_loket in jdata_Loket.slice(0, 2)" :key="rdata_loket.nama" class="col-md-6">
													<div class="card mb-3 text-start" style="max-width:800px;border-radius: 20px;">
														<div class="row g-0" style="height:150px">
															<div class="col-md-4">
																<div class="overflow-hidden text-center" style="height:100%;left: -5px;position: relative;">
																	<img v-if="(rdata_loket.tipe == 1)" src="../assets/img/thumb-pelanggan_wisata2.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																	<img v-if="(rdata_loket.tipe == 2)" src="../assets/img/thumb-pelanggan_desa1.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																</div>
															</div>
															<div class="col-md-8">
																<div class="card-body">
																	<div class="row">
																		<div class="col-12">
																			<h5 class="card-title text-bold">
																				<small><small>
																				<strong>
																					{{ rdata_loket.nama }}
																				</strong>
																				</small></small>
																			</h5>
																			<p class="card-text">
																				<img alt="Pin" src="../assets/img/pin.png" class="img-pin reguler">
																				{{ rdata_loket.alamat }}
																			</p>
																			<div class="row align-items-center">
																				<div class="col-lg-6">
																					<span class="card-text text-muted">
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>


									<div v-if="jdata_Loket.length >= 3" class="carousel-item porto">
										<div class="container">
											<div class="row">
												<div v-for="rdata_loket in jdata_Loket.slice(2, 4)" :key="rdata_loket.nama" class="col-md-6">
													<div class="card mb-3 text-start" style="max-width:800px;border-radius: 20px;">
														<div class="row g-0" style="height:150px">
															<div class="col-md-4">
																<div class="overflow-hidden text-center" style="height:100%;left: -5px;position: relative;">
																	<img v-if="(rdata_loket.tipe == 1)" src="../assets/img/thumb-pelanggan_wisata2.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																	<img v-if="(rdata_loket.tipe == 2)" src="../assets/img/thumb-pelanggan_desa1.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																</div>
															</div>
															<div class="col-md-8">
																<div class="card-body">
																	<div class="row">
																		<div class="col-12">
																			<h5 class="card-title text-bold">
																				<small><small>
																				<strong>
																					{{ rdata_loket.nama }}
																				</strong>
																				</small></small>
																			</h5>
																			<p class="card-text">
																				<img alt="Pin" src="../assets/img/pin.png" class="img-pin reguler">
																				{{ rdata_loket.alamat }}
																			</p>
																			<div class="row align-items-center">
																				<div class="col-lg-6">
																					<span class="card-text text-muted">
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>



									<div v-if="jdata_Loket.length >= 5" class="carousel-item porto">
										<div class="container">
											<div class="row">
												<div v-for="rdata_loket in jdata_Loket.slice(4, 5)" :key="rdata_loket.nama" class="col-md-6">
													<div class="card mb-3 text-start" style="max-width:800px;border-radius: 20px;">
														<div class="row g-0" style="height:150px">
															<div class="col-md-4">
																<div class="overflow-hidden text-center" style="height:100%;left: -5px;position: relative;">
																	<img v-if="(rdata_loket.tipe == 1)" src="../assets/img/thumb-pelanggan_wisata2.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																	<img v-if="(rdata_loket.tipe == 2)" src="../assets/img/thumb-pelanggan_desa1.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
																</div>
															</div>
															<div class="col-md-8">
																<div class="card-body">
																	<div class="row">
																		<div class="col-12">
																			<h5 class="card-title text-bold">
																				<small><small>
																				<strong>
																					{{ rdata_loket.nama }}
																				</strong>
																				</small></small>
																			</h5>
																			<p class="card-text">
																				<img alt="Pin" src="../assets/img/pin.png" class="img-pin reguler">
																				{{ rdata_loket.alamat }}
																			</p>
																			<div class="row align-items-center">
																				<div class="col-lg-6">
																					<span class="card-text text-muted">
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-6">
													<button class="btn rounded-pill btn-secondary slide-show-pelanggan" @click="showAllPelanggan = true;slidePelanggan = false;btnAllPelanggan = false;btnHidePelanggan = true">
														Pelanggan Lainnya ...														<!-- | + {{ cMorePelanggan }} -->
													</button>
												</div>
											</div>
										</div>
									</div>

								</div>
								<button class="carousel-control-prev porto" type="button" data-bs-target="#porto-carousel" data-bs-slide="prev">
									<span class="carousel-control-prev-icon-" aria-hidden="true">
										<i class="fa-solid fa-lg fa-angle-left" aria-hidden="true" ></i>
									</span>
									<span class="visually-hidden">Previous</span>
								</button>
								<button class="carousel-control-next porto" type="button" data-bs-target="#porto-carousel" data-bs-slide="next">

									<span class="carousel-control-next-icon-" aria-hidden="true">
										<i class="fa-solid fa-lg fa-angle-right" aria-hidden="true" ></i>
									</span>
									<span class="visually-hidden">Next</span>
								</button>
							</div>
						</div>

						<div class="row peserta-list" v-if="showAllPelanggan">
							<div v-for="rdata_loket in jdata_Loket" :key="rdata_loket.nama" class="col-md-6">
								<div v-if="(rdata_loket.no <= 4) || showAllPelanggan" class="card mb-3 text-start" style="max-width:800px;border-radius: 20px;">
									<div class="row g-0" style="height:150px">
										<div class="col-md-4">
											<div class="overflow-hidden text-center" style="height:100%;left: -5px;position: relative;">
												<img v-if="(rdata_loket.tipe == 1)" src="../assets/img/thumb-pelanggan_wisata2.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
												<img v-if="(rdata_loket.tipe == 2)" src="../assets/img/thumb-pelanggan_desa1.png" alt="Image" class="img-fluid rounded-start" style="object-fit: cover; height: 100%;">
											</div>
										</div>
										<div class="col-md-8">
											<div class="card-body">
												<div class="row">
													<div class="col-12">
														<h5 class="card-title text-bold">
															<small><small>
															<strong>
																{{ rdata_loket.nama }}
															</strong>
															</small></small>
														</h5>
														<p class="card-text">
															<img alt="Pin" src="../assets/img/pin.png" class="img-pin reguler">
															{{ rdata_loket.alamat }}
														</p>
														<div class="row align-items-center">
															<div class="col-lg-6">
																<span class="card-text text-muted">
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="jdata_Loket.length >2 && btnHidePelanggan" class="row wrapper clearfix">
					<div class="col-lg-12 text-center">
						<div v-if="btnAllPelanggan">
							<button class="btn rounded-pill btn-secondary" @click="showAllPelanggan = true;btnAllPelanggan = false;btnHidePelanggan = true">
								Pelanggan Lainnya ...
							</button>
						</div>
						<div v-if="btnHidePelanggan">
							<button class="btn rounded-pill btn-secondary" @click="showAllPelanggan = false;slidePelanggan = true;btnAllPelanggan = true;btnHidePelanggan = false">
								Tampilkan Ringkas
							</button>
						</div>

					</div>
				</div>
			</div>
		</section>
	</div>
</main>
</template>


<script>
	const setOffsetTop = 100;
	const setOffsetBootom = 5;
	const maxtxlen = 102;

	const maxViewPelanggan = 5;
	export default {
		name: 'App',
		data() {
			return {
				showAllPelanggan : false,
				btnAllPelanggan :true,
				btnHidePelanggan :false,
				slidePelanggan :true,
				cMorePelanggan : 0,
				info: 'Layanan Tamaska Loket hadir sebagai inovasi pengelolaan loket/tiket',
				iporto:0,
				jdata_Loket : []


			}
		},
		async mounted() {
			await fetch('https://dev.tamaska.id/loket/sim/backend/api/organisasi.json')
			.then(_respond => _respond.json())
			.then(
				json_data => json_data.forEach(
					el_jdata_Loket => {
						this.iporto++
						// this.bslide_show_3 = true;
						this.jdata_Loket.push({
							no:this.iporto,
							tipe:1,
							nama :el_jdata_Loket.nama,
							alamat : el_jdata_Loket.alamat.length > maxtxlen ? el_jdata_Loket.alamat.substring(0, maxtxlen - 3) + "..." : el_jdata_Loket.alamat
							})
						}
					)
				)
			.catch( _error => console.log(_error.messege))

			await fetch('https://dev.tamaska.id/loketdesa/sim/backend/api/organisasi.json')
			.then(_respond => _respond.json())
			.then(
				json_data => json_data.forEach(
					el_jdata_Loket => {
						this.iporto++
						this.cMorePelanggan = (this.iporto - maxViewPelanggan);
						this.jdata_Loket.push({
							no:this.iporto,
							tipe:2,
							nama :el_jdata_Loket.nama,
							alamat : el_jdata_Loket.alamat
							})
						}
					)
				)
			.catch( _error => console.log(_error.messege))



			window.addEventListener("scroll", this.onScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.onScroll);
		},
		methods: {
			onScroll(e) {
			// console.log(this.$route.path);

				document.getElementById("btn-home").classList.remove("active");
				document.getElementById("btn-solusi").classList.remove("active");
				document.getElementById("btn-keunggulan").classList.remove("active");
				document.getElementById("btn-modul").classList.remove("active");
				document.getElementById("btn-pelanggan").classList.remove("active");
				document.getElementById("btn-kontak").classList.remove("active");

				if(e.target.documentElement.scrollTop > (document.getElementById("solusi").offsetTop - setOffsetTop) && e.target.documentElement.scrollTop < (document.getElementById("keunggulan").offsetTop - setOffsetTop)){
					document.getElementById("btn-solusi").classList.add("active");
				}else if(e.target.documentElement.scrollTop > document.getElementById("keunggulan").offsetTop - setOffsetTop && e.target.documentElement.scrollTop < (document.getElementById("modul").offsetTop - setOffsetTop)){
					document.getElementById("btn-keunggulan").classList.add("active");
				}else if(e.target.documentElement.scrollTop > (document.getElementById("modul").offsetTop - setOffsetTop) && e.target.documentElement.scrollTop < (document.getElementById("pelanggan").offsetTop - setOffsetTop)){
					document.getElementById("btn-modul").classList.add("active");
				}else if(e.target.documentElement.scrollTop > (document.getElementById("pelanggan").offsetTop - setOffsetTop) && e.target.documentElement.scrollTop < (document.getElementById("pelanggan").offsetTop + setOffsetBootom)){
					document.getElementById("btn-pelanggan").classList.add("active");
				}else if(e.target.documentElement.scrollTop > (document.getElementById("pelanggan").offsetTop + setOffsetBootom) ){
					document.getElementById("btn-kontak").classList.add("active");
				}else{
					document.getElementById("btn-home").classList.add("active");
				}

			}
		}
    }

</script>
<style>

.carousel-indicators [data-bs-target] {

    background-color: #5EA84A;

}

button.carousel-control-prev, button.carousel-control-next {
    color: #495057;
    opacity: 0.75;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #141619;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

button.btn.rounded-pill.btn-secondary.slide-show-pelanggan {
    margin: 50px;
}

button.carousel-control-prev.porto {
    left: -100px;
}
button.carousel-control-next.porto {
    right: -100px;
}
div#porto-carousel {
    height: 220px;
}

.carousel-inner.porto {
    height: 200px;
}

@media only screen and (max-width: 990px) {
	.carousel-item {
		height: 70em;
	}
}
</style>